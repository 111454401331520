<script setup>
import { ref } from "vue";
import { goBackToSource } from "@/utils/index";
import { infoCollection } from "@/api/api.js";

import { ElMessage } from "element-plus";
const listData = ref([
  { titel: "背诵", text: "使用卡片进行记忆背诵" },
  { titel: "跟踪", text: "查看和掌握学习状态和效果" },
  { titel: "记录", text: "记录相关的笔记" },
  { titel: "分享/领取", text: "分享卡盒/领取卡盒" },
  { titel: "整理", text: "整理相关知识点" },
  { titel: "不确定", text: "不确定会干什么" },
]);

const ind = ref(-1);
function changeInd(index) {
  ind.value = index;
}

function next() {
  if (ind.value >= 0) {
    let user = JSON.parse(localStorage.getItem("userLogin"));
    infoCollection(
      `您的身份是,${listData.value[ind.value].identity}`,
      user.phone,
      3
    );
    goBackToSource(user.token);
    localStorage.clear();
  } else {
    ElMessage({
      message: "完成选择后再往下一步哦",
      type: "message",
    });
  }
}
</script>

<template>
  <div class="question-three">
    <p class="title">您会用Keymemo做什么</p>
    <p class="text">分享一些关于您的需求信息，帮助我们完善您的使用体验</p>
    <ul>
      <li
        v-for="(val, index) in listData"
        :key="index"
        :class="ind == index ? 'li_act' : ''"
        @click="changeInd(index)"
      >
        <span class="text_left">{{ val.titel }}</span
        ><span class="text_right">{{ val.text }}</span>
      </li>
    </ul>
    <div class="next_box">
      <button class="next" @click="next" :class="{ next_act: ind != -1 }">
        完成
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.next_act {
  background: #35ba9f;
  color: #fff;
}
.question-three {
  width: 600px;
  // height: 613px;
  // margin-top: -130px;
  background: #ffffff;
  box-shadow: 0px 10px 60px 1px rgba(66, 86, 105, 0.1);
  border-radius: 20px 20px 20px 20px;
  padding: 47px !important;
  box-sizing: border-box;
  .title {
    font-size: 28px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1f2328;
  }
  .text {
    margin-top: 16px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #b9bbbe;
    margin-bottom: 40px;
  }
  ul {
    li {
      width: 506px;
      height: 65px;
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
      border: 1px solid #e6e6e6;
      margin-bottom: 24px;
      padding-left: 24px;
      // padding-top: 20px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      .text_left {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #57836c;
        margin-right: 16px;
      }
      .text_right {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #a3a29d;
      }
    }
    .li_act {
      background: #35ba9f38;
    }
  }
  .next_box {
    display: flex;
    justify-content: right;
  }
  .next {
    width: 142px;
    height: 44px;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    border: 1px solid #e6e6e6;
    text-align: center;
    line-height: 44px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #a3a29d;
    cursor: pointer;
  }
  .next_act {
    background: #35ba9f;
    color: #fff;
  }
}
</style>
