<script setup>
import questionOne from "@/components/survey/questionOne.vue";
import questionTwo from "@/components/survey/questionTwo.vue";
import questionThree from "@/components/survey/questionThree.vue";
import { goBackToSource } from "@/utils/index";

import { ref } from "vue";

const type = ref(0);

function jump() {
  let user = JSON.parse(localStorage.getItem("userLogin"));
  localStorage.clear();
  goBackToSource(user.token);
}
</script>

<template>
  <div class="informationCollection">
    <span class="hover jup" @click="jump">跳过</span>
    <question-one v-if="type == 0" v-model="type" />
    <question-two v-else-if="type == 1" v-model="type"></question-two>
    <question-three v-else></question-three>
  </div>
</template>

<style lang="scss" scope>
.informationCollection {
  // position: relative;
  width: 600px;
  // overflow: hidden;
  .jup {
    position: absolute;
    top: 60px;
    right: 150px;
    font-size: 16px;
    font-weight: 500;
    color: #425669;
  }
}
// @media screen and (max-width:1800px) {
//   .jup {
//     right: -230px !important;
//   }
// }
// @media screen and (max-width:1750px) {
//   .jup {
//     right: -200px !important;
//   }
// }
// @media screen and (max-width:1700px) {
//   .jup {
//     right: -170px !important;
//   }
// }
// @media screen and (max-width:1650px) {
//   .jup {
//     right: -140px !important;
//   }
// }
// @media screen and (max-width:1600px) {
//   .jup {
//     right: -110px !important;
//   }
// }
// @media screen and (max-width:1550px) {
//   .jup {
//     right: -80px !important;
//   }
// }
// @media screen and (max-width:1450px) {
//   .jup {
//     right: -50px !important;
//   }
// }
// @media screen and (max-width:1380px) {
//   .jup {
//     right: -20px !important;
//   }
// }
// @media screen and (max-width:1300px) {
//   .jup {
//     right: 10px !important;
//   }
// }
</style>