<script setup>
import { ref, defineEmits, defineProps } from "vue";
// import { getLocalStor } from "@/utils/index";

import { ElMessage } from "element-plus";
import { infoCollection } from "@/api/api.js";
const listData = ref([
  { identity: "学生", text: "准备与学业、就业相关的考试，背诵记忆知识点" },
  { identity: "老师", text: "整理相关的背诵资料，分享给学生" },
  { identity: "家长", text: "整理相关学习资料，陪孩子一起学习" },
  { identity: "其他", text: "与职业、兴趣爱好相关" },
]);

defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

const ind = ref(-1);
function changeInd(index) {
  ind.value = index;
}
function next() {
  if (ind.value >= 0) {
    let user = JSON.parse(localStorage.getItem("userLogin"));
    infoCollection(
      `您的身份是,${listData.value[ind.value].identity}`,
      user.phone,
      1
    );
    emit("update:modelValue", 1);
  } else {
    ElMessage({
      message: "完成选择后再往下一步哦",
      type: "message",
    });
  }
}
</script>

<template>
  <div class="question-one">
    <p class="title">您的身份是</p>
    <p class="text">分享一些关于您的需求信息，帮助我们完善您的使用体验</p>
    <ul>
      <li
        v-for="(val, index) in listData"
        :key="index"
        :class="ind == index ? 'li_act' : ''"
        @click="changeInd(index)"
      >
        <span class="text_left">{{ val.identity }}</span
        ><span class="text_right">{{ val.text }}</span>
      </li>
    </ul>
    <div class="next_box">
      <button class="next" :class="ind >= 0 ? 'next_act' : ''" @click="next">
        继续
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.question-one {
  width: 600px;
  // height: 613px;
  background: #ffffff;
  box-shadow: 0px 10px 60px 1px rgba(66, 86, 105, 0.1);
  border-radius: 20px 20px 20px 20px;
  padding: 47px !important;
  box-sizing: border-box;
  position: relative;
  .title {
    font-size: 28px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1f2328;
  }
  .text {
    margin-top: 16px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #b9bbbe;
    margin-bottom: 40px;
  }
  ul {
    li {
      width: 506px;
      height: 65px;
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
      border: 1px solid #e6e6e6;
      margin-bottom: 24px;
      padding-left: 24px;
      // padding-top: 20px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      .text_left {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #35ba9f;
        margin-right: 16px;
      }
      .text_right {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #a3a29d;
      }
    }
    .li_act {
      background: #35ba9f38;
    }
  }
  .next_box {
    display: flex;
    justify-content: right;
  }
  .next {
    width: 142px;
    height: 44px;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    border: 1px solid #e6e6e6;
    text-align: center;
    line-height: 44px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #a3a29d;
    cursor: pointer;
  }
  .next_act {
    background: #35ba9f;
    color: #fff;
  }
}
</style>