<script setup>
import { ref, defineEmits, defineProps } from "vue";
// import { getLocalStor } from "@/utils/index";

import { ElMessage } from "element-plus";
import { infoCollection } from "@/api/api.js";

const listData = ref([
  { identity: "社交媒体达人种草", text: "（小红书、B站、抖音等）种草" },
  { identity: "朋友分享", text: "朋友推荐，微信朋友圈、朋友分享链接" },
  { identity: "老师、父母推荐", text: "老师和父母的推荐和要求" },
  { identity: "公众号文章", text: "公众号推广文章" },
  { identity: "其他", text: "" },
]);

const ind = ref(-1);
function changeInd(index) {
  ind.value = index;
}

defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

function next(type) {
  if (ind.value >= 0) {
    if (type == 1) {
      emit("update:modelValue", 0);
    } else {
      let user = JSON.parse(localStorage.getItem("userLogin"));
      emit("update:modelValue", 2);
      infoCollection(
        `您的身份是,${listData.value[ind.value].identity}`,
        user.phone,
        2
      );
    }
  } else {
    ElMessage({
      message: "完成选择后再往下一步哦",
      type: "message",
    });
  }
}
</script>

<template>
  <div class="question-two">
    <p class="title">你是怎么了解到Keymemo</p>
    <p class="text">分享一些关于您的需求信息，帮助我们完善您的使用体验</p>
    <ul>
      <li
        v-for="(val, index) in listData"
        :key="index"
        :class="ind == index ? 'li_act' : ''"
        @click="changeInd(index)"
      >
        <span class="text_left">{{ val.identity }}</span
        ><span class="text_right">{{ val.text }}</span>
      </li>
    </ul>
    <div class="next_box">
      <button class="next" @click="next(1)">上一个</button>
      <button class="next" :class="ind >= 0 ? 'next_act' : ''" @click="next(2)">
        继续
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.question-two {
  width: 600px;
  // height: 613px;
  // margin-top: -45px;
  margin-bottom: -45px;
  background: #ffffff;
  box-shadow: 0px 10px 60px 1px rgba(66, 86, 105, 0.1);
  border-radius: 20px 20px 20px 20px;
  padding: 47px !important;
  box-sizing: border-box;
  position: relative;
  .title {
    font-size: 28px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1f2328;
  }
  .text {
    margin-top: 16px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #b9bbbe;
    margin-bottom: 40px;
  }
  ul {
    li {
      width: 506px;
      height: 65px;
      background: #ffffff;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
      border: 1px solid #e6e6e6;
      margin-bottom: 24px;
      padding-left: 24px;
      // padding-top: 20px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      .text_left {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #35ba9f;
        margin-right: 16px;
      }
      .text_right {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #a3a29d;
      }
    }
    .li_act {
       background: #35ba9f38;
    }
  }
  .next_box {
    display: flex;
    justify-content: space-between;
  }
  .next {
    width: 142px;
    height: 44px;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    border: 1px solid #e6e6e6;
    text-align: center;
    line-height: 44px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #a3a29d;
    cursor: pointer;
  }
  .next_act {
    background: #35ba9f;
    color: #fff;
  }
}
</style>